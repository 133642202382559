@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "MabryPro";
  src: local("MabryPro"), url("./fonts/MabryPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MabryPro-Medium";
  src: local("MabryPro-Medium"),
    url("./fonts/MabryPro-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MabryPro-Bold";
  src: local("MabryPro-Bold"),
    url("./fonts/MabryPro-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: "MabryPro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif, "Jost", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* line-height: 130%; */
  /* letter-spacing: -0.01em; */
}

.mediumFont,
.mediumfont {
  font-family: "MabryPro-Medium";
}

.boldFont,
.boldfont {
  font-family: "MabryPro-Bold";
}
code {
  font-family: "MabryPro", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #141517;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  .btn-primary {
    @apply w-full text-center py-2.5 px-2  text-white text-base bg-black  rounded-md disabled:opacity-80 disabled:cursor-not-allowed;
  }
  .btn-secondary {
    @apply w-full text-center py-2.5 px-2  text-black text-base bg-white  rounded-md disabled:opacity-80 disabled:cursor-not-allowed;
  }
}

.hoverBorder {
  @apply border-l-4 border-b-4;
}
.tagRibbonBG {
  border: 1px solid #000000;
  border-radius: 4px;
  background: #000000;
  @apply flex items-center text-center justify-center;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 15px) 50%, 100% 100%, 0% 100%);
}

.tagRibbon {
  @apply flex items-center text-center justify-center absolute;
  border: 1px solid #000000;
  border-radius: 4px;
  clip-path: polygon(0% 0%, 100% 0%, calc(100% - 15px) 50%, 100% 100%, 0% 100%);
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.menuBorder {
  border-color: rgba(255, 255, 255, 0.31);
}

.buttonHover {
  @apply hover:shadow-hover hover:-translate-x-1 hover:-translate-y-1 transition-all duration-100 ease-in-out;
}
.buttonHover:active {
  @apply cursor-pointer shadow-none transition-all duration-100 ease-in-out  -translate-x-0 -translate-y-0;
}

.menuHover {
  @apply hover:shadow-hoverMenu hover:-translate-x-1 hover:-translate-y-1 transition-all duration-100 ease-in-out;
}

.cardHover {
  @apply cursor-pointer hover:shadow-hoverCard transition-all duration-100 ease-in-out  hover:-translate-x-1 hover:-translate-y-1;
}
.cardHover:active {
  @apply cursor-pointer shadow-none transition-all duration-100 ease-in-out  -translate-x-0 -translate-y-0;
}
.cardSelect {
  @apply cursor-pointer bg-[#FCF9F4] shadow-hoverCard -translate-x-1 -translate-y-1;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
